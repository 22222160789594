import React from 'react';
import "../style/services.sass";
import Slider from 'react-slick';
import "../style/global-style.sass";
import useWindowSize from '../hooks/useWindowSize';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const cssstyle = `
.container {
  width: 62%;
  padding: 8% 0%;
}
.lat-img {
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
    height: 200px;
}
.slick-prev {
    width: 3rem;
    height: 3rem;
    left: -55px
}
.slick-next {
  width: 3rem;
  height: 3rem;
  right: -55px
}

.slick-next:before, .slick-prev:before {
    color: #000;
    font-size: 3rem;
}
.slick-next:active:before, .slick-prev:active:before {
    color: #E65E27;
    
}

.center .slick-center .lat-img {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}
.center .slick-slide {
    padding: 0% 5px;
}
.center .lat-img {
    transition: all .10s ease;
}
`;

export default function LifeAtPharmsight({lifeAtPharmsight}) {
    const { width } = useWindowSize();
    const title = [
        {fragment: "Life at", color: "black"},
        {fragment: "Pharmsight", color: "#E65E27"}
    ]
    const data = [{title: "slide"},{title: "slide"},{title: "slide"},{title: "slide"},{title: "slide"},{title: "slide"}]
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: width > 768 ? 3: 1,
        centerPadding: '0px',
        speed: 500,
       
      };
  return (
    <section className='section5'>
        <div className="container">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <style>{cssstyle}</style>
        <h2 className="solutions-header">{title.map((part, index) => (
                <span style={{ color: part.color , paddingLeft: '1.5%'}}>{part.fragment}</span>
        ))}</h2>
        <p style={{fontWeight: "500", padding: "1% 0% 5% 1.5%"}}>All work and no play? Of course, not! Here's what we get up to besides our work</p>
        <Slider {...settings} style={{width: '100%'}}>
            {lifeAtPharmsight?.length && lifeAtPharmsight?.map((item, index) => {
              const image = getImage(item.image);
              return (
              <div key= {index}>
                <GatsbyImage image={image} alt="loading..." className='lat-img' />
              </div>
            )})}
        </Slider>
      </div>
    </section>
  )
}
