import React from 'react';
import PropTypes from 'prop-types';
import { Link , graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageIntroSection from '../components/PageIntroSection';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import '../style/career.sass';
import LifeAtPharmsight from '../components/LifeAtPharmsight';
import UseGoToSection from '../components/UseGoToSection';


const TitleFragment = ({ fragment, color }) => (
  <span className='innovation-title has-text-weight-semibold' style={{ color }}>{fragment + " "}</span>
);

const Innovation = ({innovation}) => {
  const image = getImage(innovation.image)
  return (
    <div className="innovation-main-container section2">
      <div className="innovation-sub-container">

        <div className="innovation-main-title">
          {innovation.title.map((item,i) => (
            <TitleFragment key={i} {...item}/>
          ))}
        </div>

        <div className="innovation-sub-1">
          <div className="innovation-sub-1-child">
            {innovation.bio.map((item,index) => (
              <div key={index} className={`innovation-sub-1-child-main main-${index}`}>
                <p className='innovation-sub-1-child-main-heading'>{item.heading}</p>
                <p className= 'innovation-sub-1-child-main-para'>{item.para}</p>
              </div>
            ))}  
          </div>
          <div className="innovation-sub-1-child-image">
              {image ? (
              <GatsbyImage image={image} alt='Loading...' className='innovation-image'/>
              ) : (
              <img src={innovation.image} alt='Loading...' className='innovation-image'/>
              )}
          </div>
        </div>

      </div>
    </div>
  )
}


const TitleFragment1 = ({ fragment, color }) => (
  <span className='work-title has-text-weight-bold' style={{ color }}>{fragment + " "}</span>
);

const Work = ({work}) => {
  return (
    <div className = 'work-main-container section3'>
        <div className = 'work-sub-container'>
          {work.title.map((item , index) => (
            <TitleFragment1 key={index} {...item}/> 
          ))}
        </div>

        <div className = 'work-sub-container-2'>
          {work.array.map((item,index) => {
              const icon = getImage(item.vector)
          return(
            <div key={index} className = 'work-sub-container-2-child'>
                
              {icon ? (
              <GatsbyImage image={icon} alt='Loading...' className='work-icon'/>
              ) : (
              <img src={item.icon} alt='Loading...' className='work-icon'/>
              )}

                <p className = 'work-sub-container-2-child-heading has-text-weight-medium'>{item.heading}</p>
                <p className = 'work-sub-container-2-child-para'>{item.sub}</p>
            </div>
          )})}

        </div>
    </div>
  )
}


const TitleFragment2 = ({ fragment, color }) => (
  <span className='position-title has-text-weight-bold' style={{ color }}>{fragment + " "}</span>
);

const Positions = ({positions}) => {

  return (
    <div className='position-main-container section4'>

      <div className='position-main-container-title'>
        {positions.title.map((item,index) => (
          <TitleFragment2 key={index} {...item}/>
        ))}
      </div>

        <div className='position-main-container-sub-1'>
          {positions.array.map((item,index) => {
            
            const image1= getImage(item.img1)
            const image2= getImage(item.img2)

            return(
          <div className='position-button'>
            <div className='position-main-container-sub-1-child' key={index}>
              <p className='has-text-weight-semibold position-main-container-sub-1-child-title' >{item.title}</p>
              <div className='position-main-container-sub-1-child-main'>

                <div className='positions-small-icons'>
                  {image1 ? (
                  <GatsbyImage image={image1} alt='Loading...' className='position-small-icon'/>
                  ) : (
                  <img src={item.img1} alt='Loading...' className='position-small-icon'/>
                  )}
                  <p>{item.site}</p>
                </div>

                <div className='positions-small-icons'>
                  {image2 ? (
                  <GatsbyImage image={image2} alt='Loading...' className='position-small-icon'/>
                  ) : (
                  <img src={item.img2} alt='Loading...' className='position-small-icon'/>
                  )}
                  <p>{item.remote}</p>
                </div>

              </div>
            </div>
          <Link to='/careers/career-subpage' className='position-bg-button-link' ><button className='position-bg-button' style={{background: item.bgColor}}>{item.buttonLabel}</button></Link>
          </div>
          )})}
          
        </div>

    </div>
  )
}


export const CareersPageTemplate = ({
  pageIntroData,
  innovation,
  work,
  positions,
  lifeAtPharmsight
}) => {
  
  return (
    <div>
      <PageIntroSection pageIntro={pageIntroData}/>
      <Innovation innovation={innovation}/>
      <Work work={work}/>
      <Positions positions={positions}/>
      <LifeAtPharmsight lifeAtPharmsight={lifeAtPharmsight}/>
      <UseGoToSection />
    </div>
  );
};

CareersPageTemplate.propTypes = {
  pageIntroData: PropTypes.shape({
    desktop: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      buttonSection: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired,
    mobile: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      backgroundVideo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      buttonSection: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired
  }),
  innovation: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    bio: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        para: PropTypes.string.isRequired,
      })
    ).isRequired,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }).isRequired,
  work: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    array: PropTypes.arrayOf(
      PropTypes.shape({
        vector: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        heading: PropTypes.string.isRequired,
        sub: PropTypes.string.isRequired,
      })
    ).isRequired,
    background: PropTypes.string.isRequired,
  }).isRequired,
  positions: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    array: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        site: PropTypes.string.isRequired,
        remote: PropTypes.string.isRequired,
        bgColor: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        img1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        img2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      })
    ).isRequired,
    background: PropTypes.string.isRequired,
  }).isRequired,
  lifeAtPharmsight: PropTypes.shape({
    image: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
  })
};

const CareersPage = ({ data }) => {
    const {frontmatter} = data.markdownRemark;
       
  return (
    <Layout>
      <CareersPageTemplate
        pageIntroData={frontmatter.pageIntroData}
        innovation={frontmatter.innovation}
        work={frontmatter.work}
        positions={frontmatter.positions}
        lifeAtPharmsight={frontmatter.lifeAtPharmsight}
      />
    </Layout>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      frontmatter {
        pageIntroData {
          desktop {
            title {
              fragment
              color
            }
            backgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            description
            buttonLabel
            buttonColor
            buttonPath
            buttonSection
            styles {
              sectionHeight
              titleFontSize
              titleFontWeight
              titleLineHeight
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
              buttonPadding
              buttonFontSize
              buttonFontWeight
            }
          }
          mobile {
            title {
              fragment
              color
            }
            backgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            description
            buttonLabel
            buttonColor
            buttonPath
            buttonSection
            styles {
              sectionHeight
              titleFontSize
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
            }
          }
        }
        innovation {
          title {
            fragment
            color
          }
          bio {
            heading
            para
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        work { 
          title {
            fragment
            color
          }
          array {
            vector {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            heading
            sub
          }
          background
        }
        positions {
          title {
            fragment
            color
          }
          array {
            title
            site
            remote
            bgColor
            buttonLabel
            img1 {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            img2 {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        lifeAtPharmsight {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`;
